import React, {useState, useRef} from 'react'
import styled from 'styled-components'
import  logoImg   from "../assets/logo.jpeg"



  
const Home = () => {
    const [ data, setData ] = useState({
        email: ''
    })

    const {email} = data
    
    const handleChange = e => {
        setData({...data, [e.target.name]: e.target.value})
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        try{
            const response = await  fetch('https://v1.nocodeapi.com/openproperty/google_sheets/fUJQErrPstiRLjSm?tabId=Sheet1', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify([[email, new Date().toLocaleDateString()]])
            }
            );
            await response.json();
            setData({...data, email: ""})
        }catch(err){
            console.log(err)
        }
    }


    
    
    
  
    return (
        <StyledBody>
            <div className="image-section">
                <div>                
                <img className="image" src={logoImg}  alt="" />
                </div>
            </div>
            <div className="text">
            <p>Input your email to get invited to<span className="bold-text"> Open Property Africa's </span> slack channel</p>
            </div>
            <div className="form-section">
            <form  onSubmit={handleSubmit}>
                <input className="email"
                 type="email" 
                 name='email'
                 value={email}
                 onChange={handleChange}
                 placeholder='Your email'
                 />
                <button className="btn" type="submit">GET MY INVITE</button>
            </form>
            </div> 

            <div className="footer">
            <p className="power">Powered by Slack</p>   
            <div>
                 <img className="image" src="https://toppng.com/uploads/preview/slack-new-logo-icon-11609376883z32jbkf8kg.png"  alt=""/>
                 </div>  
            </div>           
        </StyledBody>
    )
}

export default Home

const StyledBody = styled.div `
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
   

    .image-section{
        display: flex;
        flex-direction: row;
        height: 30px;
        width: 100%; 
        justify-content: center;
        margin-bottom: 150px;
        .image{
        height: 150px;
        width: 150px;
    }
        .plus{
            margin-top: -15px;
            color: gray;
        }
    }
    .text{
        font-style: normal;
        font-family: sans-serif;
        text-align: center;
        
        .bold-text{
            font-weight: bold;
        }
    }

    .email{
        height: 30px;
        width: 100%;
        color: black;
        border-style: solid;
        border-width: 1px;
        border-color: gray;
    }

    .btn{
        width: 100%;
        height: 30px;
        margin-top: 10px;
        border: none;
        background-color: #E01563;
        font-size: 15px;
        font-weight: bold;
        color: white;
    }
    .footer{
        display: flex;
        flex-direction: row;
        padding: 20px 0;
        .power{
            font-size: 10px;
            font-family: sans-serif;
            flex-grow: 1;
        }

        .image{
        margin-top: 5px;
        height: 20px;
        width: 20px;
        flex-grow: 2;
        }
    }
    `